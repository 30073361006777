import { LegalReportType, type LegalReportConfig } from '~/types/legalReports'

export const LEGAL_REPORTS_CONFIG_BY_ID: {
  [key in LegalReportType]: LegalReportConfig
} = {
  [LegalReportType.EGAPRO_INDEX]: {
    name: 'egaproIndex',
    slug: '/egapro-index',
    isCreationEnabled: false
  },
  [LegalReportType.BDESE]: {
    name: 'bdese',
    slug: '/bdese',
    isCreationEnabled: true
  }
}

export { LegalReportType }
